<template>
    <div class="login">

        <div class="c w-s pagebg mt-xxl">
            <div>
                <h2 class="color-accent">{{ $t('login.loginbox.title') }}</h2><br />
                <div class="text" v-html="$t('login.loginbox.text')"></div><br />
            </div>

            <div class="floating--card product">
                <div class="product--content">
                    <form id="login_form" v-show="mode != 'twofactor'" @submit.prevent="submit">
                        <fieldset>
                            <label for="login_email">{{ $t('login.fields.email') }}</label>
                            <input type="email" autocomplete="email" v-model="values.email" required autofocus />
                        </fieldset>

                        <fieldset v-if="mode == 'verify'">
                            <label for="login_password">{{ $t('login.fields.password') }}</label>
                            <input type="password" autocomplete="password" v-model="values.password" required />
                        </fieldset>

                        <div v-if="mode == 'verify'" class="text-center">
                            <button type="submit" class="w-100 mb-l bg-accent" :class="{ '--loading': loading }"
                                form="login_form">
                                {{ $t('login.loginbox.title') }}
                            </button><br />
                            <button type="button" @click="change_mode('forgotpassword');" class="w-100 bg-bg">{{
                                $t('login.actions.password_forgot') }}</button>
                            <br />
                        </div>
                        <div v-else-if="mode == 'forgotpassword'">
                            <button type="submit" class="w-100 mb-l" :loading="loading">{{
                                $t('login.actions.password_reset') }}</button><br />
                            <button type="button" @click="change_mode('verify');" class="w-100 bg-black"
                                form="login_form">{{ $t('login.actions.login') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'Login',
    data() {
        return {
            mode: 'verify',
            values: {
                email: '',
                password: '',
                code: '',
                user: null,
            },
        };
    },
    methods: {
        init_login(newv) {
            if (newv.startsWith('/logout')) {
                this.$store.dispatch('Auth/logout');
                this.$router.replace('/loggedout');
            } else if (this.$route.query.token) {
                this.$store.dispatch('Auth/startSession', { token: this.$route.query.token }).then(() => {
                    this.login_success();
                    return false;
                }).catch((err) => {
                    this.$toast({ msg: this.$t('login.toasts.error'), classes: 'bg-danger' });
                    setTimeout(() => { window.location.href = '/login/'; }, 1500);
                    console.error('login failed', err);
                });
            }
            if (!newv.startsWith('/logout')) {
                if (this.$store.state.authProviders && this.$store.state.authProviders.length === 1 && this.$store.state.authProviders[0].type === 'saml') {
                    const authUrl = this.$store.state.authProviders[0].login_url;
                    let returnPath = window.location.href;
                    returnPath = encodeURIComponent(returnPath);
                    window.location.href = `${authUrl}${encodeURIComponent(returnPath)}`;
                } else {
                    this.$store.dispatch('Auth/getSmartLock', this.values);
                }
            }
        },

        change_mode(name) {
            this.mode = name;
        },

        submit() {
            if (this.loading) { return false; }
            if (this.mode === 'verify') {
                this.$store.dispatch('Auth/login', this.values)
                    .then((r) => {
                        if (r.twofactor) {
                            this.mode = 'twofactor';
                            this.values.user = r.user;
                        } else {
                            this.login_success(r);
                        }
                    })
                    .catch(() => { });
            } else if (this.mode === 'forgotpassword') {
                this.$store.dispatch('Auth/passreset', this.values)
                    .then(() => {
                        this.mode = 'verify';
                    })
                    .catch(() => { });
            }
            return true;
        },

        submit2fa() {
            this.$store.dispatch('Auth/twofactor', this.values)
                .then((r) => {
                    this.login_success(r);
                })
                .catch(() => { });
        },

        login_success() {
            if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
            } else {
                this.$router.push('/');
            }
        },
    },
    computed: {
        loading() {
            return this.$store.state.app.loading;
        },
    },
    watch: {
        '$route.path': {
            handler: 'init_login',
            immediate: true,
        },
    },
};
</script>

<style>
.page_login #main {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: var(--padding-xxl) 0;
}

.login .state {
    min-height: 0;
}

.login {
    max-width: var(--productcolumn-width);
    width: 100%;
}

.login .product {
    padding: var(--padding-m);
}

#login_form {
    width: 100%;
}
</style>
